import React from 'react'
import { css } from '@emotion/core'

const Sign = () => {
  return (
    <div
      css={css`
        text-align: center;
      `}
    >
      <p>
        ...and that's it for today! Thank you for your time{' '}
        <span role="img" aria-label="big smile">
          😃
        </span>
      </p>
      <p>
        <i>Federico</i>
      </p>
    </div>
  )
}

export default Sign
